<template>
  <div class="wrapper_main">
    <div class="wrapper_content">
      <div class="title">{{ $t('menu.contactUs') }}</div>
      <ul class="contact_btn">
        <li @click="phoneclick" v-if="$platform.info.getPhone(regulator)">
          <div class="con_left"><img src="@/assets/uploads/phone.png" alt /></div>
          <div class="con_right">
            <p>{{ $t('withdraw.phone') }}</p>
            {{ $platform.info.getPhone(regulator) }}
          </div>
        </li>
        <li @click="emailclick">
          <div class="con_left"><img src="@/assets/uploads/email.png" alt /></div>
          <div class="con_right">
            <p>{{ $t('withdraw.email') }}</p>
            {{ GLOBAL_CONTACT_EMAIL }}
          </div>
        </li>
        <li v-if="liveChatVisible" @click="showLiveChat">
          <div class="con_left"><img src="@/assets/uploads/livechat.png" alt /></div>
          <div class="con_right">
            <p>{{ $t('liveChat.header') }}</p>
            {{ $t('liveChat.header') }}
          </div>
        </li>
      </ul>
      <!-- <ul class="info_container flex flex-between">
        <BlockView
          :title="$t('withdraw.phone')"
          :val="$platform.info.getPhone(regulator)"
          :icon="require('@/assets/uploads/phone.png')"
        />
        <BlockView
          :title="$t('withdraw.email')"
          :val="GLOBAL_CONTACT_EMAIL"
          :icon="require('@/assets/uploads/email.png')"
        />
        <BlockView :title="$t('withdraw.liveChat')" :val="'暂无'" :icon="require('@/assets/uploads/livechat.png')" />
      </ul> -->
    </div>
  </div>
</template>

<script>
import BlockView from '@/components/BlockView';
export default {
  components: { BlockView },
  data() {
    return {};
  },
  computed: {
    liveChatVisible() {
      return this.$platform.info.getLiveChatVisible(this.regulator);
    }
  },
  methods: {
    phoneclick() {
      window.location.href = 'tel:' + this.$platform.info.getPhone(this.regulator);
    },
    emailclick() {
      window.location.href = 'mailto:' + this.GLOBAL_CONTACT_EMAIL;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/contactUs.scss';
</style>
